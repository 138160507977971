import React from "react"
import { graphql } from "gatsby"

import styled from "@emotion/styled"


import SEO from "../components/seo/seo"

import Layout from "../components/layout"

const LegalPage = ({ data }) => {
  const seoTitle = data.craft.legal[0].seoTitle
  const seoDescription = data.craft.legal[0].seoDescription
  const seoImage = data.craft.legal[0].seoImage[0].url
  const seoUrl = data.craft.legal[0].slug

  const LegalContainer = styled.section`
    padding-top: 6.5rem;

    svg {
      height: 3.25rem;
      margin: 0 0 1rem 0;
    }
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`/${seoUrl}/`}
        website
      />
      <LegalContainer>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.94 47.88">
          <path
            class="cls-1"
            d="M29.93,1.86A21.07,21.07,0,0,1,32.2,3a19.07,19.07,0,0,1,9.74,16.35,18.19,18.19,0,0,1-2.28,8.76,7.67,7.67,0,0,1,1.24,4.18,7.21,7.21,0,0,1-6.53,7.36v5.52a2.21,2.21,0,0,1-2.21,2.21H28a2,2,0,0,1-.48-.06,2.17,2.17,0,0,1-1.42.54H21.92a2.32,2.32,0,0,1-1-.22,2.32,2.32,0,0,1-.95.22H15.85a2.19,2.19,0,0,1-1.42-.54,1.91,1.91,0,0,1-.48.06H9.78a2.21,2.21,0,0,1-2.21-2.21V39.67A7.21,7.21,0,0,1,1,32.31a7.67,7.67,0,0,1,1.24-4.18A18.19,18.19,0,0,1,0,19.37C0,8.69,9.41,0,21,0A22.34,22.34,0,0,1,29.93,1.86ZM21,35.68a2.7,2.7,0,0,0,2.8-2.8c-.17-1.56-1.25-5.62-2.8-5.62s-2.62,4.06-2.8,5.62A2.71,2.71,0,0,0,21,35.68ZM37.08,27.6a.05.05,0,0,1,0,0,15.78,15.78,0,0,0,2.33-8.19,16.63,16.63,0,0,0-9-14.46L28.86,6.49,31.32,9a1.26,1.26,0,0,1,0,1.78l-1.61,1.61a1.25,1.25,0,0,1-.89.37,1.23,1.23,0,0,1-.89-.37,1.26,1.26,0,0,1,0-1.78l.72-.72L26.19,7.38a1.26,1.26,0,0,1,0-1.78L28,3.79a19.85,19.85,0,0,0-7-1.27C10.8,2.52,2.52,10.08,2.52,19.37a15.63,15.63,0,0,0,2.33,8.18l0,.05a.54.54,0,0,1,0,.11.61.61,0,0,1,0,.12l0,.11a.51.51,0,0,1,0,.13.45.45,0,0,0,0,.11.62.62,0,0,1,0,.13s0,.07,0,.11l0,.12,0,.11,0,.12-.06.1a.83.83,0,0,1-.08.12l0,0a5.1,5.1,0,0,0-1.18,3.29,4.74,4.74,0,0,0,4,4.85l.44,0a4.14,4.14,0,0,0,2.08-.57l.18-.13a4.67,4.67,0,0,0,1.37-1.36,1.26,1.26,0,1,1,2.11,1.36h0a7,7,0,0,1-3.66,2.87v5.53h3.55V41.31a1.26,1.26,0,0,1,2.52,0v4h3.55V40.68a1.26,1.26,0,0,1,2.52,0v4.68h3.55v-4a1.26,1.26,0,1,1,2.52,0v3.57h3.56V39.35a7,7,0,0,1-3.67-2.87h0a1.26,1.26,0,0,1,2.12-1.36,4.52,4.52,0,0,0,1.36,1.36l.19.13a4.09,4.09,0,0,0,2.08.57l.43,0a4.74,4.74,0,0,0,4-4.85A5.15,5.15,0,0,0,37.21,29l0,0-.08-.12-.05-.1a.61.61,0,0,1,0-.12.36.36,0,0,1,0-.11l0-.12s0-.08,0-.11a.56.56,0,0,1,0-.13v-.11l0-.13a.36.36,0,0,1,0-.11,1.17,1.17,0,0,1,.05-.11A.67.67,0,0,1,37.08,27.6ZM18.37,25.45a5.5,5.5,0,1,0-5.49,5.49A5.5,5.5,0,0,0,18.37,25.45Zm16.19,0a5.5,5.5,0,1,0-5.49,5.49A5.5,5.5,0,0,0,34.56,25.45Z"
          />
        </svg>
        <h1>That legal stuff...</h1>
        <div
          dangerouslySetInnerHTML={{ __html: data.craft.legal[0].legalBody }}
        />
      </LegalContainer>
    </Layout>
  )
}

export const LegalContent = graphql`
  query LegalContent {
    craft {
      legal: entries(section: "legal") {
        dateCreated
        dateUpdated
        slug
        title
        ... on Craft_legal_legal_Entry {
          seoTitle
          seoDescription
          seoImage {
            title
            url
          }
          legalBody
        }
      }
    }
  }
`

export default LegalPage
